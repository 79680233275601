import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import EvaluationPage from "./pages/evaluation/evaluation";
import EvaluationList from "./pages/evaluation/evaluation_list";
import LandingPage from "./pages/landing_page/LandingPage";
import LocalHelper from "./utils/localStorageHelper";
// import http from "./utils/http";
// import LocalHelper from "./utils/localStorageHelper";
import ProtectedRoute from "./utils/ProtectedRoute";

function App() {

  
  
  let [ user, setUser ] = useState()

  useEffect(()=>{
    let userData = localStorage.getItem("token")
    
    if (Boolean(userData)) {
      changeUser(LocalHelper.getData('user'))
    }
  },[])

  let changeUser = (user)=>{
    setUser(user)
  }

	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<LandingPage user={user} setUser={changeUser} />} />
				<Route element={<ProtectedRoute user={user} />}>
          <Route path="evaluation" element={<EvaluationList setUser={changeUser} user={user}/> } />
          <Route path="evaluation/:id"  element={<EvaluationPage/>} />
        </Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
