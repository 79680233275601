const Match = (props) => {
    return(
        <div className="flex flex-col shadow-md border-2 bg-primary-900 md:flex-row">
            <div className="w-full md:basis-2/3 md:flex-1">
                <img src={props.imgSrc} alt="gambar"/>
            </div>
            <div className="p-4 py-16 flex flex-col justify-center text-center md:py-10 md:basis-1/3">
                <div className="text-white">
                    <h4 className="font-bold text-2xl">{props.title}</h4>
                    <p className="text-base">{props.date}</p>
                    <p className="text-lg font-bold">{props.locate}</p>
                </div>
                <div className="text-base font-bold">
				    <button className="border-2 rounded p-1 mt-4 bg-white text-primary-900 shadow-inner w-36 flex-initial">
					    <a 
                        // class="mayar-button iframe-lightbox-link" 
                        href="https://jfos-indonesia.mayar.link/pl/liga-psba-pembinaan-sepak-bola-anak?iframe=true" target="_blank" rel="noreferrer" data-padding-bottom="30%" data-scrolling="true">
                            <p>
                                Pre-Order Sekarang
                            </p>
                        </a>
					    {/* <script type="text/javascript" src="https://pub-fa933e278fb7467aa20592e0a61f5082.r2.dev/mayar-min.js"></script> */}
				    </button>
                </div>
            </div>
        </div>
    )
}
export default Match