const MyFooter = () => {
	return (
		<footer className="py-4 px-4 bg-primary-900 text-white text-center md:px-20 md:py-10 lg:px-40">
			<p className="font-bold">Jember Football School</p>
			<p className="text-sm">081358524652</p>
			<p className="text-sm">081336681197</p>
			<p className="text-sm">
				Secaba (Sekolah Calon Bintara), Jalan Tidar No.10, Karangrejo,
				Kloncing, Kec. Sumbersari, Kabupaten Jember, Jawa Timur
			</p>
		</footer>
	);
};

export default MyFooter;
