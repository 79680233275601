import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper";

const MatchCarousel = (props) => {
	const dateData = props.match.date.split("-");
					// console.log(dateData[0]);
					const date = new Date();
					date.setMonth(Number(dateData[1]) - 1);
					const monthName = date.toLocaleString("id", {
						month: "long",
					})
	return (
		<div
			className={
				"flex flex-col shadow-md " +
				(props.isReverse ? "lg:flex-row-reverse" : "lg:flex-row  ")
			}
		>
			<div
				className={
					"p-4 flex flex-col lg:w-80 justify-center text-center " +
					(props.isReverse
						? "bg-white text-primary-800"
						: "bg-primary-800 text-white  ")
				}
			>
				<h4 className="font-bold text-2xl">{props.match.title}</h4>
				<p className="text-base">{`${dateData[2]} ${monthName} ${dateData[0]}`}</p>
				<p className="text-lg font-bold">{props.match.place}</p>
			</div>
			<Swiper
				modules={[Autoplay]}
				spaceBetween={50}
				autoplay
				slidesPerView={1}
				className="w-full h-72 lg:flex-1"
			>
				{props.match.photos.map((value, index) => {
					return (
						<SwiperSlide
							key={index}
							style={{
								// backgroundImage: `url('https://www.sportsbusinessjournal.com/-/media/Images/Journal/2022/08/29/pg-09-Soccer-Getty.ashx')`,
								backgroundImage: `url('https://admin.jemberfootballschool.com/${value.path}')`,
							}}
							className="bg-cover"
						></SwiperSlide>
					);
				})}
			</Swiper>
		</div>
	);
};

export default MatchCarousel;
