const ReviewCard = (props) => {
	return (
		<div className="p-4 rounded-md bg-primary-100 md:basis-1/3 mb-4">
			<div className="flex gap-4">
				{/* <div className="h-12 w-12 bg-slate-500 rounded-full"></div> */}
				<div className="flex flex-col">
					<h6 className="font-bold text-base">{props.title}</h6>
					<p className="text-sm">{props.subtitle}</p>
				</div>
			</div>
			<p className="mt-3 text-sm">
				{props.content}
			</p>
		</div>
	);
};

export default ReviewCard;
