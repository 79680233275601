import MatchCarousel from "./component/MatchCarousel";
import Match from './component/Match'
import ProgramCard from "./component/ProgramCard";
import ReviewCard from "./component/ReviewCard";
import JfosLogo from "../../assets/jfos_logo.png";
import IconWa from "../../assets/ic_wa.png";
import IconIg from "../../assets/ic_ig.png";
import ParentsMeetingCarousel from "./component/ParentsMeetingCarousel";
import MyFooter from "../../component/MyFooter";
import { useState, useEffect } from "react";
import http from "../../utils/http";
import { useNavigate } from "react-router-dom";
import LoginModal from "./component/LoginModal";
import { FaUser } from "react-icons/fa";
import LocalHelper from "../../utils/localStorageHelper";
import ImgPersonal from "../../assets/img_personal.svg";
import ImgCamp from "../../assets/img_camp.svg";
import ImgCompetition from "../../assets/img_competition.svg";
import ImgLevel from "../../assets/img_level.svg";
import ImgLicense from "../../assets/img_license.svg";
import ImgParenting from "../../assets/img_parenting.svg";
import ImgSchool from "../../assets/img_school.svg";
import ImgTraining from "../../assets/img_training.svg";
import ImgLiga from "../../assets/LIGA.png"
import { faker } from "@faker-js/faker";

const LandingPage = (props) => {
	let [showAllReview, setShowAllReview] = useState(false);
	let [showAllMatch, setShowAllMatch] = useState(false);
	let [showAllMeeting, setShowAllMeeting] = useState(false);
	let [showLogin, setShowLogin] = useState(false);

	let [showProfile, setShowProfile] = useState(false);

	let [matches, setMatches] = useState([]);
	let [meeting, setMeeting] = useState([]);

	let navigate = useNavigate();

	useEffect(() => {
		async function getPageData() {
			let res = await http.get("/landing");
			setMatches(res.data.data.match);
			setMeeting(res.data.data.parent_meetings);
		}

		getPageData();
	}, []);

	const handleScroll = () => {
		const sectionId = document.getElementById('pertandinganId')

		if(sectionId){
			sectionId.scrollIntoView({ behavior: 'smooth' })
		}
	}

	function handleShowEvaluation() {
		if (props.user == null) {
			setShowLogin(true);
		} else {
			navigate("/evaluation");
		}
	}

	function togleProfile() {
		setShowProfile(!showProfile);
	}

	async function handleLogout() {
		props.setUser(null);
		setShowProfile(false);
		LocalHelper.deleteData("token");
	}

	return (
		<div className="">
			<section className="bg-primary-700">
				<nav className="flex justify-between items-center px-6 md:px-20 lg:px-40 xl:px-60">
					<div className="flex items-center">
						<img src={JfosLogo} className="w-16" alt="Logo" />
						<p className="ml-2 hidden md:block text-2xl font-bold text-white">
							SSB JFOS INDONESIA
						</p>
					</div>
					<div className="flex gap-4 items-center">
						<button
							onClick={handleShowEvaluation}
							className="border-2 px-3 py-1 rounded border-primary-500 text-white hover:bg-primary-500"
						>
							Lihat Hasil Evaluasi
						</button>
						{!props.user ? (
						<button
							onClick={() => setShowLogin(true)}
							className="border-2 px-3 py-1 rounded border-white text-white hover:bg-white hover:text-primary-500 "
						>
							Login
						</button>
						) : (
							<div className="relative">
								<FaUser
									onClick={togleProfile}
									className="w-6 h-6 fill-white cursor-pointer"
								></FaUser>
								{showProfile && (
									<div className="absolute p-4 bg-white rounded right-0 top-full mt-4 ">
										<h3 className="text-gray-500">Akun</h3>
										<p className="font-bold">
											{props.user.nama}
										</p>
										<p className="">{props.user.email}</p>
										<button
											onClick={() => {
												handleLogout();
											}}
											className="px-4 py-2 bg-primary-700 text-white rounded mt-3"
										>
											Log Out
										</button>
									</div>
								)}
							</div>
						)}
					</div>
				</nav>
			</section>
			<section className="bg-whiteColor min-h-72 py-4 px-6 relative sm:px-10 md:px-14 lg:px-20 xl:px-60">
				<div className="fixed z-30 right-4 flex flex-col bottom-4 gap-2 ">
					<span
						onClick={() => {
							window.open(
								"https://instagram.com/jember.fs?igshid=YmMyMTA2M2Y=",
								"_blank"
							);
						}}
						className="cursor-pointer bg-white flex align-middle justify-center items-center rounded-full w-12 h-12 shadow-md"
					>
						<img src={IconIg} className="w-6" alt="" />
					</span>
					<span
						onClick={() => {
							window.open(
								"https://wa.me/6281358524652",
								"_blank"
							);
						}}
						className="cursor-pointer bg-white flex align-middle justify-center items-center rounded-full w-12 h-12 shadow-md"
					>
						<img src={IconWa} className="w-6" alt="" />
					</span>
				</div>
				<div className="flex w-full py-3 cursor-pointer sm:py-6 md:py-10 lg:py-14" onClick={handleScroll}>
					<img src={ImgLiga} alt="Produk1"/>
				</div>
				{/* <h1 className="text-5xl mt-36 mb-8 sm:mt-32 md:mt-28 md:text-6xl lg:text-7xl text-white font-bold">
					JEMBER FOOTBALL SCHOOL
				</h1>
				<div className="text-sm lg:text-base text-white mb-3">
					<p className="font-semibold">
						Visi:
					</p>
					<p>Dengan SEPAK BOLA, bergotong-royong membentuk generasi yang berketuhanan YME, cinta tanah air, sehat, cerdas, berintegritas tinggi, berkarakter positif, bijaksana, dan inovatif.</p>
				</div> */}
				{/* <div className="left-4 right-4 p-10 bg-white absolute -bottom-24 md:left-20 md:right-20 lg:left-40 lg:right-40 md:-bottom-16 lg:-bottom-24 lg:p-20 xl:left-60 xl:right-60">
					Lorem ipsum dolor sit amet consectetur adipisicing elit.
					Possimus voluptate qui, maiores velit aliquid reiciendis rem
					inventore. Suscipit ut, amet maxime totam qui perferendis.
				</div> */}
			</section>
			<section className="px-6 pt-10 pb-20 bg text-white md:px-20 lg:px-40 xl:px-60">
				<h1 className="text-3xl text-center font-bold mt-10 mb-10 md:mt-14 lg:mt-20 md:mb-12 lg:mb-18 md:text-5xl lg:text-6xl">
					JEMBER FOOTBALL SCHOOL
				</h1>
				<div className="text-center mb-6">
					<p className="font-bold text-lg mb-2">
						Visi
					</p>
					<p className="text-base">
						Dengan SEPAK BOLA, bergotong-royong membentuk generasi yang berketuhanan YME, cinta tanah air, sehat, cerdas, berintegritas tinggi, berkarakter positif, bijaksana, dan inovatif.
					</p>
				</div>
				<p className="font-bold text-center text-lg mt-4 mb-2">
					Misi
				</p>
				<ol className="text-justify text-base list-decimal">
					<li>Melahirkan pemain SEPAK BOLA yang berkualitas, berkarakter, istimewa, dan berprestasi</li>
					<li>Membina secara intensif dan terpersonalisasi menggunakan metode yang berorientasi pada siswa/pemain untuk memaksimalkan kemampuan intelegency, personality, technic, maupun speed</li>
					<li>Menerapkan kurikulum filanesia dengan dipadukan dan dikembangkan sesuai perkembangan ilmu persepakbolaan</li>
					<li>Membina dan memupuk skill-skill lain yang menunjang masa depan karier pemain</li>
				</ol>
			</section>
			<section className="px-6 pt-10 pb-20 bg-whiteColor md:px-20 lg:px-40 xl:px-60">
				{/* <div className="text-sm left-10 right-10 p-5 bg-white absolute -top-16 md:left-16 md:right-16 lg:text-base lg:left-24 lg:right-24 md:-top-12 lg:-top-14 lg:p-10 xl:left-60 xl:right-60">
					<p className="font-semibold">
						Misi:
					</p>
					<p>1. Melahirkan pemain SEPAK BOLA yang berkualitas, berkelas, istimewa, dan berprestasi.</p>
        			<p>2. Membina secara intensif dan terpersonalisasi menggunakan metode yang berorientasi pada siswa/pemain untuk memaksimalkan kemampuan intelegency, personality, technic, maupun speed</p>
        			<p>3. Menerapkan kurikulum filanesia dengan dipadukan dan dikembangkan sesuai perkembangan ilmu persepakbolaan</p>
        			<p>4. Membina dan memupuk skill-skill lain yang menunjang masa depan karier pemain</p>
				</div> */}
				<h2 className="text-3xl text-center text-primary-900 font-bold mt-10 mb-10 md:mt-14 lg:mt-20 md:mb-12 lg:mb-18 md:text-5xl lg:text-6xl">
					PROGRAM - PROGRAM
				</h2>
				<div className="grid grid-cols-1 gap-4 gap-y-6 lg:grid-cols-2 lg:gap-8">
					<ProgramCard
						title="Latihan Sore"
						imgSrc={ImgTraining}
						desc="Latihan rutin sepak bola sesuai kurikulum filanesia untuk kelompok U10, U13, dan U15 di lapangan secaba Jember."
					/>
					<ProgramCard
						title="Training Camp"
						imgSrc={ImgCamp}
						desc="Pelatihan sepak bola intensif dengan porsi latihan yang sudah disesuaikan untuk persiapan kompetisi."
					/>
					<ProgramCard
						title="Boarding School"
						imgSrc={ImgSchool}
						desc="Asrama sekolah sepak bola yang memberikan materi sepak bola sekaligus mata pelajaran lainnya dengan sistem sekolah non-formal."
					/>
					<ProgramCard
						title="Parenting"
						imgSrc={ImgParenting}
						desc="Diskusi dan materi pendukung seputar sepak bola dan gizi yang diberikan untuk orangtua wali murid pemain sepak bola."
					/>
				</div>
			</section>
			<section className="pt-10 pb-20 px-6 md:px-20 lg:px-40 xl:px-60">
				<h2 className="text-3xl text-center text-primary-900 font-bold mt-10 mb-10 md:mt-14 lg:mt-20 md:mb-12 lg:mb-18 md:text-5xl lg:text-6xl">
					KEUNGGULAN
				</h2>
				<div className="grid grid-cols-1 gap-8 gap-y-8 lg:grid-cols-2 ">
					<ProgramCard
						title="Evaluasi Personalisasi"
						imgSrc={ImgPersonal}
						desc="Memberikan evaluasi personal baik secara langsung ataupun berupa dokumen evaluasi untuk setiap pemain secara berkala."
					/>
					<ProgramCard
						title="Program Berjenjang"
						imgSrc={ImgLevel}
						desc="Program latihan untuk setiap kelompok usia memiliki porsi latihan yang berbeda beda sesuai ketentuan kurikulum filanesia."
					/>
					<ProgramCard
						title="Pelatih Berlisensi"
						imgSrc={ImgLicense}
						desc="Pelatihan sepak bola dipandu langsung oleh Coach sepak bola berlisensi C Asprov PSSI Jawa Timur."
					/>
					<ProgramCard
						title="Aktif Dalam Kompetisi Sesuai PSSI"
						imgSrc={ImgCompetition}
						desc="Berpartisipasi aktif dalam kompetisi yang sesuai dengan arahan PSSI untuk meningkatkan pengalaman pemain."
					/>
				</div>
			</section>
			<section className="bg-whiteColor pt-10 pb-20 px-6 text-center md:px-20 lg:px-40 xl:px-60" id="pertandinganId">
				<h2 className="text-3xl text-center text-primary-900 font-bold mt-10 mb-10 md:mt-14 lg:mt-20 md:mb-12 lg:mb-18 md:text-5xl lg:text-6xl">
					PERTANDINGAN TERBARU
				</h2>
				<Match
					title="Liga PSBA"
					date="28 Mei (Pekan 1)"
					locate="Lap. Secaba Jember"
					imgSrc={ImgLiga}
				/>
			</section>
			{/* {matches.length > 0 && (
				<section className="py-20 px-4 bg-whiteColor md:px-36 lg:px-52 ">
					<h2 className="text-4xl text-primary-900 text-center font-bold mb-20">
						PERTANDINGAN TERBARU
					</h2>
					<div className="flex flex-col gap-8">
						{matches
							.slice(0, showAllMatch ? matches.length + 1 : 3)
							.map((value, index) => {
								return (
									<MatchCarousel
										match={value}
										key={index}
										isReverse={index % 2 === 1}
									/>
								);
							})}
					</div>
					{matches.length > 3 && (
						<button
							onClick={() => {
								setShowAllMatch(!showAllMatch);
							}}
							className="bg-primary-700 px-4 py-2 text-white block mt-4 rounded-md mx-auto"
						>
							{showAllMatch ? "Lebih sedikit" : "Lebih banyak"}
						</button>
					)}
				</section>
			)} */}
			{/* {meeting.length > 0 && (
				<section className="py-20 px-4 bg-whiteColor md:px-36 lg:px-72 xl:px-96">
					<h2 className="text-5xl text-center text-primary-900 font-bold mb-20">
						AKTIVITAS KAMI
					</h2>
					<div className="flex flex-col gap-8">
						{meeting
							.slice(0, showAllMeeting ? meeting.length + 1 : 3)
							.map((value, index) => {
								return (
									<ParentsMeetingCarousel
										meeting={value}
										key={index}
										isReverse={index % 2 === 1}
									/>
								);
							})}
					</div>
					{meeting.length > 3 && (
						<button
							onClick={() => {
								setShowAllMeeting(!showAllMeeting);
							}}
							className="bg-primary-700 px-4 py-2 text-white block mt-4 rounded-md mx-auto"
						>
							{showAllMeeting ? "Lebih sedikit" : "Lebih banyak"}
						</button>
					)}
				</section>
			)} */}
			<section className="pt-10 pb-20 px-6 md:px-20 lg:px-40 xl:px-60">
				<h2 className="text-3xl text-center text-primary-900 font-bold mt-10 mb-10 md:mt-14 lg:mt-20 md:mb-12 lg:mb-18 md:text-5xl lg:text-6xl">
					TESTIMONI
				</h2>
				<div
					className={
						"grid gap-4 grid-cols-1 overflow-hidden relative md:grid-cols-2 lg:grid-cols-3 " +
						(showAllReview ? "" : "h-[60vh]")
					}
				>
					<div className="">
						{[...Array(9)].map((value, index) => {
							return (
								<ReviewCard
									title={faker.name.fullName()}
									subtitle={
										String(faker.datatype.number(30)) +
										" Tahun"
									}
									content={faker.lorem.sentences(2)}
									key={index}
								/>
							);
						})}
					</div>
					<div className="hidden md:block">
						{[...Array(9)].map((value, index) => {
							return (
								<ReviewCard
									title={faker.name.fullName()}
									subtitle={
										String(faker.datatype.number(30)) +
										" Tahun"
									}
									content={faker.lorem.sentences(2)}
									key={index}
								/>
							);
						})}
					</div>
					<div className="hidden lg:block">
						{[...Array(9)].map((value, index) => {
							return (
								<ReviewCard
									title={faker.name.fullName()}
									subtitle={
										String(faker.datatype.number(30)) +
										" Tahun"
									}
									content={faker.lorem.sentences(2)}
									key={index}
								/>
							);
						})}
					</div>
					<div className="h-[350px] flex justify-center w-full bg-gradient-to-t from-white absolute bottom-0">
						<button
							onClick={() => {
								setShowAllReview(!showAllReview);
							}}
							className="bg-primary-700 px-4 py-2 mb-4 text-white rounded-md absolute bottom-0"
						>
							{showAllReview ? "Lebih sedikit" : "Lebih banyak"}
						</button>
					</div>
				</div>
			</section>
			<MyFooter />
			<LoginModal
				onClose={() => setShowLogin(false)}
				isShow={showLogin}
				setUser={props.setUser}
			/>
		</div>
	);
};

export default LandingPage;
