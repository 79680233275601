let LocalHelper = {
    getData : (key) =>{
        let data = localStorage.getItem(key)
        return JSON.parse(data)
    },
    setData : (key, value)=>{
        localStorage.setItem(key, JSON.stringify(value))
    },

    deleteData : (key)=>{
        localStorage.removeItem(key)
    }
}

export default LocalHelper