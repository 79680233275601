import { useState } from "react";
import { useNavigate } from "react-router-dom";
import http from "../../../utils/http";
import LocalHelper from "../../../utils/localStorageHelper";
import ReactLoading from "react-loading"

const LoginModal = (props) => {
	let [error, setError] = useState();
	let [email, setEmail] = useState();
	let [password, setPassword] = useState();
	let [isLoading, setIsLoading] = useState(false);

	let navigate = useNavigate();

	async function handleLogin(e) {
		e.preventDefault();
		setIsLoading(true)
		try {
			let res = await http.post("login", {
				email: email,
				password: password,
			});

			if (!res.data.status) {
				throw res.data.message
			}

			props.setUser(res.data.data);
			LocalHelper.setData("user", res.data.data);
			LocalHelper.setData("token", res.data.accessToken);
			setIsLoading(false)
			setError(null);
			props.onClose();
			navigate("evaluation");
		} catch (error) {
			setError(error);
			setIsLoading(false)
		}
	}

	if (!props.isShow) {
		return null;
	}

	return (
		<div
			onClick={(e) => {
				if (e.target === e.currentTarget) {
					props.onClose();
				}
			}}
			className="fixed flex items-center justify-center top-0 bottom-0 left-0 right-0 bg-opacity-30 bg-black z-50"
		>
			<div className="bg-whiteColor w-full rounded-md block m-4 opacity-100 px-8 py-12 sm:w-1/2 lg:w-1/3 ">
				<h2 className="font-bold text-4xl text-primary-800 text-center">
					Login
				</h2>
				<p className="text-gray-500 mt-3 text-center">
					Login untuk melihat evaluasi pemain
				</p>
				<form
					className="mt-9"
					onSubmit={(e) => {
						handleLogin(e);
					}}
				>
					<label htmlFor="" className="text-primary-800">
						Email
					</label>
					<br />
					<input
						type="email"
						className="px-4 py-2 w-full rounded mt-2"
						value={email}
						required
						onChange={(e) => setEmail(e.target.value)}
					/>
					<div className="mt-4"></div>
					<label htmlFor="" className="text-primary-800">
						Password
					</label>
					<br />
					<input
						type="password"
						className="px-4 py-2 w-full rounded mt-2"
						value={password}
						required
						onChange={(e) => setPassword(e.target.value)}
					/>
					{error != null && (
						<p className="text-red-500 mt-2 text-center">{error}</p>
					)}
					<div className="flex justify-center cursor-pointer mt-4 gap-4">
						<div
							className="bg-whiteColor font-bold border border-primary-700 text-primary-700 px-8 py-2 rounded mt-4"
							onClick={props.onClose}
						>
							Kembali
						</div>
						<button
							className="bg-primary-700 font-bold text-white px-8 py-2 rounded mt-4 "
							type="submit"
						>
							{!isLoading && "Login"}
							{isLoading && <ReactLoading className="mx-auto" height={16} width={16} type="spin"/>}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default LoginModal;
