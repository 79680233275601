import { useLocation, useNavigate } from "react-router-dom";
// import { useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
// import { Document, Page, pdfjs  } from "react-pdf/dist/esm/entry.webpack5";

const EvaluationPage = (props) => {
	const location = useLocation();
	let pdfUrl = location.state.url;

	let navigate = useNavigate()

	// const [numPages, setNumPages] = useState(null);

	// function onDocumentLoadSuccess({ numPages }) {
	// 	setNumPages(numPages);
	// }
	// window.frames["pdfFile"].contentDocument.oncontextmenu = function(){
	// 	return false; 
	//  };

	// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

	return (
		<div className="bg-[#525659]">
			<div onClick={()=>navigate(-1)} className="flex items-center p-4 text-primary-800 bg-white shadow fixed z-10 w-full cursor-pointer">
				<FaAngleLeft className="mr-1 text-2xl"/>
				<p className="">Kembali</p>
			</div>
			{/* <div
				onContextMenu={(e) => e.preventDefault()}
				className="flex justify-center lg:p-10"
			>
				<Document
					className="overflow-scroll mt-16"
					file={pdfUrl} // Ganti link pdf disini
					options={pdfjs.GlobalWorkerOptions}
					onLoadSuccess={onDocumentLoadSuccess}
				>
					{Array.from(new Array(numPages), (el, index) => (
						<Page
							className="md:border m-4 p-3 rounded-xl md:shadow-md"
							key={`page_${index + 1}`}
							pageNumber={index + 1}
						/>
					))}
				</Document>
			</div> */}
			<iframe title="Hasil Evaluasi" className="w-full h-screen pt-16" src={pdfUrl + "#toolbar=0"} id="pdfFile"></iframe>
		</div>
	);
};

export default EvaluationPage;
