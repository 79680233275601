import { useState } from "react";
import LocalHelper from "../../utils/localStorageHelper";
import { FaUser, FaAngleRight } from "react-icons/fa";
import JfosLogo from "../../assets/jfos_logo.png";
import { useNavigate, Link } from "react-router-dom";
import { useEffect } from "react";
import http from "../../utils/http";
import ReactLoading from "react-loading";

const EvaluationList = (props) => {
	let [showProfile, setShowProfile] = useState(false);
	let [isLoading, setIsLoading] = useState(false);
	let [evaluation, setEvaluation] = useState([]);

	let navigate = useNavigate()

	useEffect(() => {
		getEvaluation();
		// eslint-disable-next-line
	}, []);

	async function getEvaluation() {
		try {
			setIsLoading(true);
			let res = await http.get("/wali", {
				headers: {
					Authorization: LocalHelper.getData("token"),
				},
			});
			setIsLoading(false);
			setEvaluation(res.data.data);
		} catch (error) {
			setIsLoading(false);
			if (error.response.status === 401) {
				handleLogout();
			}
		}
	}

	function togleProfile() {
		setShowProfile(!showProfile);
	}


	function toEvalPage(path, url){
		navigate(path,
		{
			state: { url : url },
		} )
	}

	async function handleLogout() {
		props.setUser(null);
		setShowProfile(false);
		LocalHelper.deleteData("token");
	}
	return (
		<div className="">
			<header className="px-6 py-2 flex shadow-sm items-center justify-between">
				<Link to="/" className="flex items-center">
					<img src={JfosLogo} className="w-16" alt="Logo" />
					<p className="ml-2 hidden md:block text-2xl font-bold text-primary-900">
						SSB JFOS INDONESIA
					</p>
				</Link>
				<div className="relative flex gap-4">
					<h2 className="font-bold">{props.user.nama}</h2>
					<FaUser
						onClick={togleProfile}
						className="w-6 h-6 fill-primary-900 cursor-pointer"
					></FaUser>
					{showProfile && (
						<div className="absolute p-4 bg-white rounded right-0 top-full mt-4 shadow-md ">
							<h3 className="text-gray-500">Akun</h3>
							<p className="font-bold">{props.user.nama}</p>
							<p className="">{props.user.email}</p>
							<button
								onClick={() => {
									handleLogout();
								}}
								className="px-4 py-2 bg-primary-700 text-white rounded mt-3"
							>
								Log Out
							</button>
						</div>
					)}
				</div>
			</header>
			<section className="p-4 md:px-20 md:py-12 lg:px-40 xl:px-60 bg-slate-50 h-screen">
				<h2 className="text-2xl font-bold text-primary-900">
					Evaluasi Pemain
				</h2>
				<br />
				{evaluation.map((item, index) => {
					const dateData = item.tanggal.split("/");
					// console.log(dateData[0]);
					const date = new Date();
					date.setMonth(Number(dateData[0]) - 1);
					const monthName = date.toLocaleString("id", {
						month: "long",
					});
					return (
						<div
							key={index}
							onClick={()=>{
								toEvalPage(monthName + "-" + dateData[1], "https://admin.jemberfootballschool.com"+item.path)
							}}
							className="shadow-sm p-4 mb-3 rounded hover:shadow cursor-pointer bg-white flex justify-between items-center"
						>
							<h4 className="font-medium">
								{monthName + " " + dateData[1]}
							</h4>
							<FaAngleRight></FaAngleRight>
						</div>
					);
				})}
				{isLoading && (
					<ReactLoading
						className="mx-auto"
						color="#3C8398"
						type="cylon"
					/>
				)}
			</section>
		</div>
	);
};

export default EvaluationList;
