
const ProgramCard = (props) => {
	return (
		<div className="flex gap-4 items-center">
			{/* <div className="w-24 h-24 bg-gray-500"></div> */}
			<img className="w-40" alt="img" src={props.imgSrc} />
			<div className="flex-1">
				<h3 className="font-bold text-lg text-primary-700">{props.title}</h3>
				<p className="text-justify">
					{props.desc}
				</p>
			</div>
		</div>
	);
};

export default ProgramCard;
